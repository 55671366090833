export const Box = {
  name: 'Box',
  attrs: {
    active: {
      name: 'active',
      id: 'Box.active',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    dimensions: {
      name: 'dimensions',
      id: 'Box.dimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    editable: {
      name: 'editable',
      id: 'Box.editable',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    exportId: {
      name: 'exportId',
      id: 'Box.exportId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    free: {
      name: 'free',
      id: 'Box.free',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    geolocation: {
      name: 'geolocation',
      id: 'Box.geolocation',
      type: {
        name: 'object',
        of: 'Coordinates'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'Box.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    inUse: {
      name: 'inUse',
      id: 'Box.inUse',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    isShared: {
      name: 'isShared',
      id: 'Box.isShared',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lock: {
      name: 'lock',
      id: 'Box.lock',
      type: {
        name: 'object',
        of: 'BoxLock'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lockEnabled: {
      name: 'lockEnabled',
      id: 'Box.lockEnabled',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'Box.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    sharedFromContract: {
      name: 'sharedFromContract',
      id: 'Box.sharedFromContract',
      type: {
        name: 'integer'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    sharingClients: {
      name: 'sharingClients',
      id: 'Box.sharingClients',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'ClientUser'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    size: {
      name: 'size',
      id: 'Box.size',
      type: {
        name: 'enum',
        id: 'Box$BoxSize',
        of: ['XS', 'SMALL', 'MEDIUM', 'LARGE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Box$BoxSize'
      }
    },
    sizeAsXs: {
      name: 'sizeAsXs',
      id: 'Box.sizeAsXs',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    visible: {
      name: 'visible',
      id: 'Box.visible',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    waitingForReadinessCheck: {
      name: 'waitingForReadinessCheck',
      id: 'Box.waitingForReadinessCheck',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    withCamera: {
      name: 'withCamera',
      id: 'Box.withCamera',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
