export const ClientWarehouseListItem = {
  name: 'ClientWarehouseListItem',
  attrs: {
    address: {
      name: 'address',
      id: 'ClientWarehouseListItem.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    boxFrom: {
      name: 'boxFrom',
      id: 'ClientWarehouseListItem.boxFrom',
      type: {
        name: 'object',
        of: 'BoxFrom'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'ClientWarehouseListItem.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    coords: {
      name: 'coords',
      id: 'ClientWarehouseListItem.coords',
      type: {
        name: 'object',
        of: 'Coordinates'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    country: {
      name: 'country',
      id: 'ClientWarehouseListItem.country',
      type: {
        name: 'enum',
        id: 'Country',
        of: ['SVK', 'CZE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Country'
      }
    },
    deactivation: {
      name: 'deactivation',
      id: 'ClientWarehouseListItem.deactivation',
      type: {
        name: 'object',
        of: 'DeactivationInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    discount: {
      name: 'discount',
      id: 'ClientWarehouseListItem.discount',
      type: {
        name: 'object',
        of: 'PromoDiscount'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    distance: {
      name: 'distance',
      id: 'ClientWarehouseListItem.distance',
      type: {
        name: 'double'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'ClientWarehouseListItem.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    hasFreeBoxes: {
      name: 'hasFreeBoxes',
      id: 'ClientWarehouseListItem.hasFreeBoxes',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'ClientWarehouseListItem.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    img: {
      name: 'img',
      id: 'ClientWarehouseListItem.img',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'ClientWarehouseListItem.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
