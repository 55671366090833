export const apiEnums = {
  CountryCode: {
    name: 'enum',
    id: 'CountryCode',
    of: [
      'SVK',
      'CZE',
      'ABW',
      'AFG',
      'AGO',
      'AIA',
      'ALA',
      'ALB',
      'AND',
      'ARE',
      'ARG',
      'ARM',
      'ASM',
      'ATA',
      'ATF',
      'ATG',
      'AUS',
      'AUT',
      'AZE',
      'BDI',
      'BEL',
      'BEN',
      'BES',
      'BFA',
      'BGD',
      'BGR',
      'BHR',
      'BHS',
      'BIH',
      'BLM',
      'BLR',
      'BLZ',
      'BMU',
      'BOL',
      'BRA',
      'BRB',
      'BRN',
      'BTN',
      'BVT',
      'BWA',
      'CAF',
      'CAN',
      'CCK',
      'CHE',
      'CHL',
      'CHN',
      'CIV',
      'CMR',
      'COD',
      'COG',
      'COK',
      'COL',
      'COM',
      'CPV',
      'CRI',
      'CUB',
      'CUW',
      'CXR',
      'CYM',
      'CYP',
      'DEU',
      'DJI',
      'DMA',
      'DNK',
      'DOM',
      'DZA',
      'ECU',
      'EGY',
      'ERI',
      'ESH',
      'ESP',
      'EST',
      'ETH',
      'FIN',
      'FJI',
      'FLK',
      'FRA',
      'FRO',
      'FSM',
      'GAB',
      'GBR',
      'GEO',
      'GGY',
      'GHA',
      'GIB',
      'GIN',
      'GLP',
      'GMB',
      'GNB',
      'GNQ',
      'GRC',
      'GRD',
      'GRL',
      'GTM',
      'GUF',
      'GUM',
      'GUY',
      'HKG',
      'HMD',
      'HND',
      'HRV',
      'HTI',
      'HUN',
      'IDN',
      'IMN',
      'IND',
      'IOT',
      'IRL',
      'IRN',
      'IRQ',
      'ISL',
      'ISR',
      'ITA',
      'JAM',
      'JEY',
      'JOR',
      'JPN',
      'KAZ',
      'KEN',
      'KGZ',
      'KHM',
      'KIR',
      'KNA',
      'KOR',
      'KWT',
      'LAO',
      'LBN',
      'LBR',
      'LBY',
      'LCA',
      'LIE',
      'LKA',
      'LSO',
      'LTU',
      'LUX',
      'LVA',
      'MAC',
      'MAF',
      'MAR',
      'MCO',
      'MDA',
      'MDG',
      'MDV',
      'MEX',
      'MHL',
      'MKD',
      'MLI',
      'MLT',
      'MMR',
      'MNE',
      'MNG',
      'MNP',
      'MOZ',
      'MRT',
      'MSR',
      'MTQ',
      'MUS',
      'MWI',
      'MYS',
      'MYT',
      'NAM',
      'NCL',
      'NER',
      'NFK',
      'NGA',
      'NIC',
      'NIU',
      'NLD',
      'NOR',
      'NPL',
      'NRU',
      'NZL',
      'OMN',
      'PAK',
      'PAN',
      'PCN',
      'PER',
      'PHL',
      'PLW',
      'PNG',
      'POL',
      'PRI',
      'PRK',
      'PRT',
      'PRY',
      'PSE',
      'PYF',
      'QAT',
      'REU',
      'ROU',
      'RUS',
      'RWA',
      'SAU',
      'SDN',
      'SEN',
      'SGP',
      'SGS',
      'SHN',
      'SJM',
      'SLB',
      'SLE',
      'SLV',
      'SMR',
      'SOM',
      'SPM',
      'SRB',
      'SSD',
      'STP',
      'SUR',
      'SVN',
      'SWE',
      'SWZ',
      'SXM',
      'SYC',
      'SYR',
      'TCA',
      'TCD',
      'TGO',
      'THA',
      'TJK',
      'TKL',
      'TKM',
      'TLS',
      'TON',
      'TTO',
      'TUN',
      'TUR',
      'TUV',
      'TWN',
      'TZA',
      'UGA',
      'UKR',
      'UMI',
      'URY',
      'USA',
      'UZB',
      'VAT',
      'VCT',
      'VEN',
      'VGB',
      'VIR',
      'VNM',
      'VUT',
      'WLF',
      'WSM',
      'YEM',
      'ZAF',
      'ZMB',
      'ZWE'
    ]
  },
  Contract$Status: {
    name: 'enum',
    id: 'Contract$Status',
    of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
  },
  ReferralRewardTransaction$Type: {
    name: 'enum',
    id: 'ReferralRewardTransaction$Type',
    of: ['CREDIT', 'DEBIT']
  },
  RentPaying$PayMethod: {
    name: 'enum',
    id: 'RentPaying$PayMethod',
    of: ['AUTOPAY', 'MANUAL', 'FROM_ORDER', 'MARKED_BY_ADMIN']
  },
  RentPaying$Status: {
    name: 'enum',
    id: 'RentPaying$Status',
    of: ['CREATED', 'ATTEMPTING_AUTOPAY', 'WAITING_FOR_MANUAL', 'PAID']
  },
  Country: {
    name: 'enum',
    id: 'Country',
    of: ['SVK', 'CZE']
  },
  AppUser$Type: {
    name: 'enum',
    id: 'AppUser$Type',
    of: ['ClientUser', 'OwnerUser', 'AdminUser']
  },
  Box$BoxSize: {
    name: 'enum',
    id: 'Box$BoxSize',
    of: ['XS', 'SMALL', 'MEDIUM', 'LARGE']
  },
  LockVersion: {
    name: 'enum',
    id: 'LockVersion',
    of: ['V1', 'V2']
  },
  ContractCancellationRequest$Reason: {
    name: 'enum',
    id: 'ContractCancellationRequest$Reason',
    of: [
      'PotrebujemPriestoryBlizsie',
      'NevyhovujeMiCena',
      'NevyhovujeMiVelkostPriestoru',
      'UzNepotrebujem',
      'Ine'
    ]
  },
  Gender: {
    name: 'enum',
    id: 'Gender',
    of: ['Male', 'Female']
  },
  DayOfWeek: {
    name: 'enum',
    id: 'DayOfWeek',
    of: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY'
    ]
  },
  IsoEra: {
    name: 'enum',
    id: 'IsoEra',
    of: ['BCE', 'CE']
  },
  Month: {
    name: 'enum',
    id: 'Month',
    of: [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER'
    ]
  },
  PromoType: {
    name: 'enum',
    id: 'PromoType',
    of: [
      'Easter',
      'Christmas',
      'Snowflake',
      'Gem',
      'Gift',
      'Heart',
      'Leaf',
      'Sun',
      'Umbrella'
    ]
  },
  InvoicingDetails$Type: {
    name: 'enum',
    id: 'InvoicingDetails$Type',
    of: ['PravnickaOsoba', 'FyzickaOsoba']
  },
  SuperAdminJobsRestApi$Job: {
    name: 'enum',
    id: 'SuperAdminJobsRestApi$Job',
    of: [
      'PAYMENT_PROCESSING',
      'CANCEL_CONTRACTS',
      'NOTIFY_RENTS_WITH_PAYMENT_OVERDUE',
      'NOTIFY_PAYMENTS_TO_BE_ANNOUNCED',
      'NOTIFY_CONTRACTS_BEFORE_TERMINATION'
    ]
  },
  JobRun$Status: {
    name: 'enum',
    id: 'JobRun$Status',
    of: ['IN_PROGRESS', 'FINISHED', 'FAILED']
  },
  Payment$GatewayType: {
    name: 'enum',
    id: 'Payment$GatewayType',
    of: ['STRIPE', 'GOPAY', 'MOCK']
  },
  RentStatus: {
    name: 'enum',
    id: 'RentStatus',
    of: ['CREATED', 'INVOICE_SUBMITTED', 'PAID']
  },
  Order$Status: {
    name: 'enum',
    id: 'Order$Status',
    of: ['SUBMITTED', 'FULFILLED', 'FAILED', 'CANCELLED']
  },
  Language: {
    name: 'enum',
    id: 'Language',
    of: ['SK', 'CS', 'EN']
  },
  RefundStatus: {
    name: 'enum',
    id: 'RefundStatus',
    of: ['CREATED', 'AUTO_SUCCESS', 'AUTO_FAIL']
  },
};
