import { HigherApiClient } from 'src/api/client/ApiClient'
import { Dashboard } from 'src/api/defs/Dashboard.type'
import { apiOps } from 'src/api/defs/apiOps'
import { AdminWarehouse } from 'src/api/defs/AdminWarehouse.type'
import { DashboardFilter as ApiDashboardFilter } from 'src/api/defs/DashboardFilter.type'
import { strIdToNum } from 'src/domain/strIdToNum'
import { DayInterval } from 'src/api/defs/DayInterval.type'
import { WarehousePodorys } from 'src/api/defs/WarehousePodorys.type'

const ops = apiOps.Dashboard

export type DashboardFilter = {
    interval: DayInterval
    warehouseId: string | null
}

export const toApiDashboardFilter = (
    filter: DashboardFilter
): ApiDashboardFilter => ({
    interval: filter.interval,
    warehouseId:
        filter.warehouseId == null ? undefined : strIdToNum(filter.warehouseId)
})

export class DashboardApi {
    constructor(private api: HigherApiClient) {}

    get = (filter: DashboardFilter): Promise<Dashboard> =>
        this.api.do<Dashboard>(ops.get, {
            queryParams: toApiDashboardFilter(filter)
        })

    availableWarehouses = (): Promise<{ name: string; id: string }[]> =>
        this.api.do<AdminWarehouse[]>(ops.availableWarehouses).then(items =>
            items.map(i => ({
                name: i.name,
                id: i.id.toString()
            }))
        )

    getPodorys = (id: string): Promise<WarehousePodorys> =>
        this.api.doForId<WarehousePodorys>(ops.getPodorys, null, id)
}
