import { createFluentModelBuilder } from '@ps-aux/api-model-extensions'
import { allModels, api } from 'src/api/defs'
import { AdminWarehouse } from 'src/api/defs/AdminWarehouse.type'
import { ClientUser } from 'src/api/defs/ClientUser.type'
import { AppUser } from 'src/api/defs/AppUser.type'
import { Address } from 'src/api/defs/Address.type'
import { Coordinates } from 'src/api/defs/Coordinates.type'
import { CompanyDetails } from 'src/api/defs/CompanyDetails.type'
import { PublicTransportAccessibility } from 'src/api/defs/PublicTransportAccessibility.type'
import { Money } from 'src/api/defs/Money.type'
import { AdminContractListItem } from 'src/api/defs/AdminContractListItem.type'
import { AdminContract } from 'src/api/defs/AdminContract.type'
import { BoxDimensions } from 'src/api/defs/BoxDimensions.type'
import { AdminWarehouseListItem } from 'src/api/defs/AdminWarehouseListItem.type'
import { VatPrice } from 'src/api/defs/VatPrice.type'
import { JobRun } from 'src/api/defs/JobRun.type'
import { SuperAdminOrder } from 'src/api/defs/SuperAdminOrder.type'
import { AdminRent } from 'src/api/defs/AdminRent.type'
import { AdminReferralRewardTransaction } from 'src/api/defs/AdminReferralRewardTransaction.type'
import { Box } from 'src/api/defs/Box.type'
import { BoxWithInUseInfo } from 'src/api/defs/BoxWithInUseInfo.type'
import { Owner } from 'src/api/defs/Owner.type'
import { WarehouseCreateCmd } from 'src/api/defs/WarehouseCreateCmd.type'
import { MyContract } from 'src/api/defs/MyContract.type'
import { Day } from 'src/api/defs/Day.type'
import { KpiPerInterval } from 'src/api/defs/KpiPerInterval.type'
import { KpiPerWarehouse } from 'src/api/defs/KpiPerWarehouse.type'
import { KpiObsadenost } from 'src/api/defs/KpiObsadenost.type'
import { WarehouseFilterForAdmin } from 'src/api/defs/WarehouseFilterForAdmin.type'
import { BoxFilter } from 'src/api/defs/BoxFilter.type'
import { Tenant } from 'src/api/defs/Tenant.type'
import { AppUserFilter } from 'src/api/defs/AppUserFilter.type'
import { AdminRentFilter } from 'src/api/defs/AdminRentFilter.type'
import { OwnerAndClientContractFilter } from 'src/api/defs/OwnerAndClientContractFilter.type'
import { WarehouseContractFilter } from 'src/api/defs/WarehouseContractFilter.type'
import { ReferralRewardTransactionFilter } from 'src/api/defs/ReferralRewardTransactionFilter.type'
import { DashboardFilter } from 'src/api/defs/DashboardFilter.type'
import { BrowserErrorReport } from '../defs/BrowserErrorReport.type'
import { FranchiseContact } from '../defs/FranchiseContact.type'
import { BoxV1Lock } from '../defs/BoxV1Lock.type'
import { BoxV2Lock } from '../defs/BoxV2Lock.type'
import { WarehouseV1Lock } from '../defs/WarehouseV1Lock.type'
import { WarehouseV2Lock } from '../defs/WarehouseV2Lock.type'
import { ClientUserFilter } from '../defs/ClientUserFilter.type'
import { RysAuditListItem } from '../defs/RysAuditListItem.type'
import { RysAuditFilter } from '../defs/RysAuditFilter.type'
import { UnlockTokenInfo } from '../defs/UnlockTokenInfo.type'

const b = createFluentModelBuilder(allModels)

// TODO create ts type and generate automatically in runtime
export const m = {
    Warehouse: b.of<AdminWarehouse>(api.AdminWarehouse),
    WarehouseV1Lock: b.of<WarehouseV1Lock>(api.WarehouseV1Lock),
    WarehouseV2Lock: b.of<WarehouseV2Lock>(api.WarehouseV2Lock),
    BoxV1Lock: b.of<BoxV1Lock>(api.BoxV1Lock),
    BoxV2Lock: b.of<BoxV2Lock>(api.BoxV2Lock),
    WarehouseCreateCmd: b.of<WarehouseCreateCmd>(api.WarehouseCreateCmd),
    WarehouseListItem: b.of<AdminWarehouseListItem>(api.AdminWarehouseListItem),
    AdminRent: b.of<AdminRent>(api.AdminRent),
    ClientUser: b.of<ClientUser>(api.ClientUser),
    AppUser: b.of<AppUser>(api.AppUser),
    AdminContractListItem: b.of<AdminContractListItem>(
        api.AdminContractListItem
    ),
    AdminContract: b.of<AdminContract>(api.AdminContract),
    MyContract: b.of<MyContract>(api.MyContract),
    Address: b.of<Address>(api.Address),
    Coordinates: b.of<Coordinates>(api.Coordinates),
    CompanyDetails: b.of<CompanyDetails>(api.CompanyDetails),
    Money: b.of<Money>(api.Money),
    MoneyWithoutCurrency: b.of<Money>(api.Money),
    VatPrice: b.of<VatPrice>(api.VatPrice),
    PublicTransportAccessibility: b.of<PublicTransportAccessibility>(
        api.PublicTransportAccessibility
    ),
    BoxDimensions: b.of<BoxDimensions>(api.BoxDimensions),
    JobRun: b.of<JobRun>(api.JobRun),
    Order: b.of<SuperAdminOrder>(api.SuperAdminOrder),
    RysAuditListItem: b.of<RysAuditListItem>(api.RysAuditListItem),
    AdminReferralRewardTransaction: b.of<AdminReferralRewardTransaction>(
        api.AdminReferralRewardTransaction
    ),
    Box: b.of<Box>(api.Box),
    BoxWithInUseInfo: b.of<BoxWithInUseInfo>(api.BoxWithInUseInfo),
    Owner: b.of<Owner>(api.Owner),
    Day: b.of<Day>(api.Day),
    Tenant: b.of<Tenant>(api.Tenant),
    KpiPerInterval: b.of<KpiPerInterval>(api.KpiPerInterval),
    KpiPerWarehouse: b.of<KpiPerWarehouse>(api.KpiPerWarehouse),
    KpiObsadenost: b.of<KpiObsadenost>(api.KpiObsadenost),
    WarehouseFilter: b.of<WarehouseFilterForAdmin>(api.WarehouseFilterForAdmin),
    BoxFilter: b.of<BoxFilter>(api.BoxFilter),
    WarehouseContractFilter: b.of<WarehouseContractFilter>(
        api.WarehouseContractFilter
    ),
    OwnerAndClientContractFilter: b.of<OwnerAndClientContractFilter>(
        api.OwnerAndClientContractFilter
    ),
    AppUserFilter: b.of<AppUserFilter>(api.AppUserFilter),
    ClientUserFilter: b.of<ClientUserFilter>(api.ClientUserFilter),
    AdminRentFilter: b.of<AdminRentFilter>(api.AdminRentFilter),
    ReferralRewardTransactionFilter: b.of<ReferralRewardTransactionFilter>(
        api.ReferralRewardTransactionFilter
    ),
    DashboardFilter: b.of<DashboardFilter>(api.DashboardFilter),
    RysAuditFilter: b.of<RysAuditFilter>(api.RysAuditFilter),
    BrowserErrorReport: b.of<BrowserErrorReport>(api.BrowserErrorReport),
    FranchiseContact: b.of<FranchiseContact>(api.FranchiseContact),
    UnlockTokenInfo: b.of<UnlockTokenInfo>(api.UnlockTokenInfo)
}
