export const OrderSummaryRequest = {
  name: 'OrderSummaryRequest',
  attrs: {
    language: {
      name: 'language',
      id: 'OrderSummaryRequest.language',
      type: {
        name: 'enum',
        id: 'Language',
        of: ['SK', 'CS', 'EN']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Language'
      }
    },
    order: {
      name: 'order',
      id: 'OrderSummaryRequest.order',
      type: {
        name: 'object',
        of: 'OrderInputs'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
