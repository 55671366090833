export const RysAuditListItem = {
    name: 'RysAuditListItem',
    attrs: {
        businessId: {
            name: 'businessId',
            id: 'RysAuditListItem.businessId',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        clazz: {
            name: 'clazz',
            id: 'RysAuditListItem.clazz',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        contractId: {
            name: 'contractId',
            id: 'RysAuditListItem.contractId',
            type: {
                name: 'integer'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        coordinatesLat: {
            name: 'coordinatesLat',
            id: 'RysAuditListItem.coordinatesLat',
            type: {
                name: 'double'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        coordinatesLng: {
            name: 'coordinatesLng',
            id: 'RysAuditListItem.coordinatesLng',
            type: {
                name: 'double'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        createdAt: {
            name: 'createdAt',
            id: 'RysAuditListItem.createdAt',
            type: {
                name: 'date'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        exceptionClazz: {
            name: 'exceptionClazz',
            id: 'RysAuditListItem.exceptionClazz',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        exceptionMessage: {
            name: 'exceptionMessage',
            id: 'RysAuditListItem.exceptionMessage',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        facilityId: {
            name: 'facilityId',
            id: 'RysAuditListItem.facilityId',
            type: {
                name: 'integer'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        id: {
            name: 'id',
            id: 'RysAuditListItem.id',
            type: {
                name: 'integer'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        isAlert: {
            name: 'isAlert',
            id: 'RysAuditListItem.isAlert',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        lockId: {
            name: 'lockId',
            id: 'RysAuditListItem.lockId',
            type: {
                name: 'integer'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        uri: {
            name: 'uri',
            id: 'RysAuditListItem.uri',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        userName: {
            name: 'userName',
            id: 'RysAuditListItem.userName',
            type: {
                name: 'string'
            },
            required: false,
            validationRules: [],
            extra: {}
        },
        warehouseId: {
            name: 'warehouseId',
            id: 'RysAuditListItem.warehouseId',
            type: {
                name: 'integer'
            },
            required: false,
            validationRules: [],
            extra: {}
        }
    }
};
