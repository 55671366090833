export const OrderSummary = {
  name: 'OrderSummary',
  attrs: {
    box: {
      name: 'box',
      id: 'OrderSummary.box',
      type: {
        name: 'object',
        of: 'Box'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    deposit: {
      name: 'deposit',
      id: 'OrderSummary.deposit',
      type: {
        name: 'object',
        of: 'BoxDepositOption'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    firstMonthFreeDiscountInfo: {
      name: 'firstMonthFreeDiscountInfo',
      id: 'OrderSummary.firstMonthFreeDiscountInfo',
      type: {
        name: 'object',
        of: 'FirstMonthFreeDiscountInfo'
      },
      required: false,
      validationRules: [],
      extra: {}
    },
    insurance: {
      name: 'insurance',
      id: 'OrderSummary.insurance',
      type: {
        name: 'object',
        of: 'BoxInsuranceOption'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoice: {
      name: 'invoice',
      id: 'OrderSummary.invoice',
      type: {
        name: 'object',
        of: 'InvoicePreview'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicing: {
      name: 'invoicing',
      id: 'OrderSummary.invoicing',
      type: {
        name: 'object',
        of: 'ContractInvoicingOption'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    owner: {
      name: 'owner',
      id: 'OrderSummary.owner',
      type: {
        name: 'object',
        of: 'Owner'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouse: {
      name: 'warehouse',
      id: 'OrderSummary.warehouse',
      type: {
        name: 'object',
        of: 'ClientWarehouse'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
