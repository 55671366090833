export const MarkPaidCmd = {
  name: 'MarkPaidCmd',
  attrs: {
    paidAt: {
      name: 'paidAt',
      id: 'MarkPaidCmd.paidAt',
      type: {
        name: 'string'
      },
      required: false,
      validationRules: [],
      extra: {}
    }
  }
};
