import React, { ReactNode } from 'react'
import ReactSelect, {
    components,
    ControlProps,
    Theme as RSTheme
} from 'react-select'
import { Option, SelectComp } from 'src/ui/basic/api/Select'
import { useTheme } from 'src/ui/style/theme/useTheme'
import { css } from 'src/ui/style/css'

const myControlComp = (rightContent?: () => ReactNode) => ({
    children,
    ...rest
}: ControlProps<Option<any>, false>) => (
    <components.Control {...rest}>
        {children}
        {rightContent && (
            <div
                css={css`
                    margin: 0.25em 0.5em;
                    display: flex;
                    align-items: center;
                `}
                // Stops propagating click from select (show/hide options)
                onMouseDown={e => {
                    e.stopPropagation()
                }}
            >
                {rightContent()}
            </div>
        )}
    </components.Control>
)

type ReactSelectTheme = Omit<RSTheme, 'borderRadius'> & {
    borderRadius: string | number
}

export const Select: SelectComp = ({
    value,
    onChange,
    noValueOption = false,
    options,
    enabled = true,
    isEqual = (a: any, b: any) => a === b,
    className,
    rightContent,
    displayDropdownIndicator = false,
    placeholder,
    searchable = false
}) => {
    const th = useTheme()

    const busy = !options

    if (busy) {
        options = []
    }
    const selectedOption = options.find(o => isEqual(o.value, value)) || null

    return (
        <ReactSelect
            className={className}
            isDisabled={busy || !enabled}
            value={selectedOption}
            isLoading={busy}
            isSearchable={searchable}
            isClearable={!!noValueOption}
            onChange={(o: any) => onChange(o ? o.value : null)}
            styles={{
                container: provided => ({
                    ...provided,
                    width: '100%'
                }),
                control: (provided, s) => ({
                    ...provided,
                    cursor: 'pointer',
                    minHeight: th.input.height,
                    height: th.input.height,
                    borderColor: th.input.borderColor,
                    ...(s.isFocused
                        ? {
                              ...th.input.focus,
                              borderColor: `${th.colors.outline} !important`
                          }
                        : null),
                    '&:hover': {}
                }),
                option: provided => ({
                    ...provided,
                    cursor: 'pointer'
                }),
                menu: provided => ({
                    ...provided,
                    margin: 0
                }),
                menuList: provided => ({
                    ...provided,
                    padding: 0
                })
            }}
            // If null, placeholder is set to 'Select..', so that's the reason for '' if null
            placeholder={placeholder || ''}
            options={options}
            components={{
                Control: myControlComp(rightContent),
                IndicatorSeparator: () => null,
                DropdownIndicator: displayDropdownIndicator
                    ? components.DropdownIndicator
                    : () => null
            }}
            // @ts-ignore
            theme={(theme: RSTheme): ReactSelectTheme => ({
                ...theme,
                borderRadius: th.borderRadius.primary,
                colors: {
                    ...theme.colors,
                    primary: th.colors.outline,
                    primary25: th.colors.hover,
                    primary50: th.colors.primary,
                    neutral80: th.fontColor.primary
                },
                spacing: {
                    ...theme.spacing
                }
            })}
        />
    )
}
