import { Accessibility } from './Accessibility.model';
import { Address } from './Address.model';
import { AdminContract } from './AdminContract.model';
import { AdminContractListItem } from './AdminContractListItem.model';
import { AdminReferralRewardTransaction } from './AdminReferralRewardTransaction.model';
import { AdminRent } from './AdminRent.model';
import { AdminRentFilter } from './AdminRentFilter.model';
import { AdminRentPaying } from './AdminRentPaying.model';
import { AdminWarehouse } from './AdminWarehouse.model';
import { AdminWarehouseListItem } from './AdminWarehouseListItem.model';
import { AppUser } from './AppUser.model';
import { AppUserFilter } from './AppUserFilter.model';
import { AutoregistrationInvoicingDetailErrors } from './AutoregistrationInvoicingDetailErrors.model';
import { AutoregistrationInvoicingDetails } from './AutoregistrationInvoicingDetails.model';
import { AutoregistrationPlacedOrderResponse } from './AutoregistrationPlacedOrderResponse.model';
import { BooleanValue } from './BooleanValue.model';
import { Box } from './Box.model';
import { BoxConsumerPrice } from './BoxConsumerPrice.model';
import { BoxDepositOption } from './BoxDepositOption.model';
import { BoxDimensions } from './BoxDimensions.model';
import { BoxFilter } from './BoxFilter.model';
import { BoxFrom } from './BoxFrom.model';
import { BoxGeometry } from './BoxGeometry.model';
import { BoxGeometryInput } from './BoxGeometryInput.model';
import { BoxGroup } from './BoxGroup.model';
import { BoxInUseInfo } from './BoxInUseInfo.model';
import { BoxInsurance } from './BoxInsurance.model';
import { BoxInsuranceOption } from './BoxInsuranceOption.model';
import { BoxItem } from './BoxItem.model';
import { BoxLock } from './BoxLock.model';
import { BoxLockInfo } from './BoxLockInfo.model';
import { BoxV1Lock } from './BoxV1Lock.model';
import { BoxV2Lock } from './BoxV2Lock.model';
import { BoxWithInUseInfo } from './BoxWithInUseInfo.model';
import { BrowserErrorContext } from './BrowserErrorContext.model';
import { BrowserErrorReport } from './BrowserErrorReport.model';
import { CancelContractCmd } from './CancelContractCmd.model';
import { ChangeMyPasswordCmd } from './ChangeMyPasswordCmd.model';
import { ClientContractRestApiTypes } from './ClientContractRestApiTypes.model';
import { ClientInfo } from './ClientInfo.model';
import { ClientReferralInfo } from './ClientReferralInfo.model';
import { ClientUser } from './ClientUser.model';
import { ClientUserFilter } from './ClientUserFilter.model';
import { ClientWarehouse } from './ClientWarehouse.model';
import { ClientWarehouseListItem } from './ClientWarehouseListItem.model';
import { CompanyDetails } from './CompanyDetails.model';
import { ContractCancellationOptions } from './ContractCancellationOptions.model';
import { ContractInvoicingOption } from './ContractInvoicingOption.model';
import { ContractInvoicingType } from './ContractInvoicingType.model';
import { Coordinates } from './Coordinates.model';
import { CreateBoxCmd } from './CreateBoxCmd.model';
import { CurrentObsadenost } from './CurrentObsadenost.model';
import { Dashboard } from './Dashboard.model';
import { DashboardFilter } from './DashboardFilter.model';
import { Day } from './Day.model';
import { DayCmd } from './DayCmd.model';
import { DayInterval } from './DayInterval.model';
import { DeactivateWarehouseCmd } from './DeactivateWarehouseCmd.model';
import { DeactivationInfo } from './DeactivationInfo.model';
import { DebitCmd } from './DebitCmd.model';
import { DeductionFromDepositCommand } from './DeductionFromDepositCommand.model';
import { Discount } from './Discount.model';
import { Document } from './Document.model';
import { DownloadLink } from './DownloadLink.model';
import { EditBoxCmd } from './EditBoxCmd.model';
import { EntityCreated } from './EntityCreated.model';
import { Features } from './Features.model';
import { FirstMonthFree } from './FirstMonthFree.model';
import { FirstMonthFreeDiscountInfo } from './FirstMonthFreeDiscountInfo.model';
import { FranchiseContact } from './FranchiseContact.model';
import { FranchiseContactFilter } from './FranchiseContactFilter.model';
import { FranchisingContactRequest } from './FranchisingContactRequest.model';
import { IntegerValue } from './IntegerValue.model';
import { Interval } from './Interval.model';
import { InvoiceItem } from './InvoiceItem.model';
import { InvoiceItemVatPrice } from './InvoiceItemVatPrice.model';
import { InvoiceItems } from './InvoiceItems.model';
import { InvoicePreview } from './InvoicePreview.model';
import { InvoicingDetails } from './InvoicingDetails.model';
import { IsoChronology } from './IsoChronology.model';
import { JobRestApiTypes } from './JobRestApiTypes.model';
import { JobRun } from './JobRun.model';
import { Kpi } from './Kpi.model';
import { KpiObsadenost } from './KpiObsadenost.model';
import { KpiObsadenostAreal } from './KpiObsadenostAreal.model';
import { KpiObsadenostDaily } from './KpiObsadenostDaily.model';
import { KpiObsadenostNumeral } from './KpiObsadenostNumeral.model';
import { KpiPerInterval } from './KpiPerInterval.model';
import { KpiPerWarehouse } from './KpiPerWarehouse.model';
import { KpiStatistikyZmluv } from './KpiStatistikyZmluv.model';
import { Locale } from './Locale.model';
import { LockPin } from './LockPin.model';
import { MarkPaidCmd } from './MarkPaidCmd.model';
import { Money } from './Money.model';
import { MoneyWithoutCurrency } from './Money.model';
import { MyContract } from './MyContract.model';
import { MyContractFilter } from './MyContractFilter.model';
import { MyRent } from './MyRent.model';
import { MyRentFilter } from './MyRentFilter.model';
import { OrderInputs } from './OrderInputs.model';
import { OrderOffer } from './OrderOffer.model';
import { OrderResult } from './OrderResult.model';
import { OrderSummary } from './OrderSummary.model';
import { OrderSummaryRequest } from './OrderSummaryRequest.model';
import { Owner } from './Owner.model';
import { OwnerAndClientContractFilter } from './OwnerAndClientContractFilter.model';
import { OwnerEditCmd } from './OwnerEditCmd.model';
import { PayManuallyCmd } from './PayManuallyCmd.model';
import { PayManuallyResponse } from './PayManuallyResponse.model';
import { PlaceOrderCmd } from './PlaceOrderCmd.model';
import { PlacedOrderInfo } from './PlacedOrderInfo.model';
import { PodorysBox } from './PodorysBox.model';
import { Point } from './Point.model';
import { PromoDiscount } from './PromoDiscount.model';
import { PromoDiscountEffect } from './PromoDiscountEffect.model';
import { PromoWithBoxes } from './PromoWithBoxes.model';
import { PublicTransportAccessibility } from './PublicTransportAccessibility.model';
import { ReferralRewardAccountState } from './ReferralRewardAccountState.model';
import { ReferralRewardTransactionFilter } from './ReferralRewardTransactionFilter.model';
import { RefundCommand } from './RefundCommand.model';
import { RegisterUserCmd } from './RegisterUserCmd.model';
import { RegistrationResponse } from './RegistrationResponse.model';
import { ReportError } from './ReportError.model';
import { RequestCancellationCmd } from './RequestCancellationCmd.model';
import { RuntimeInfo } from './RuntimeInfo.model';
import { RysAuditListItem } from './RysAuditListItem.model'
import { RysAuditFilter } from './RysAuditFilter.model'
import { SetGeometryCmd } from './SetGeometryCmd.model';
import { SetInvocingDetailsCmd } from './SetInvocingDetailsCmd.model';
import { SetLocalizationCmd } from './SetLocalizationCmd.model';
import { SubscribeToNewFreeBoxBody } from './SubscribeToNewFreeBoxBody.model';
import { SuperAdminOrder } from './SuperAdminOrder.model';
import { SwaggerPageable } from './SwaggerPageable.model';
import { Tenant } from './Tenant.model';
import { VatPrice } from './VatPrice.model';
import { WarehouseBoxPrice } from './WarehouseBoxPrice.model';
import { WarehouseContractFilter } from './WarehouseContractFilter.model';
import { WarehouseCreateCmd } from './WarehouseCreateCmd.model';
import { WarehouseDiscount } from './WarehouseDiscount.model';
import { WarehouseEditCmd } from './WarehouseEditCmd.model';
import { WarehouseFilterForAdmin } from './WarehouseFilterForAdmin.model';
import { WarehouseForOwnerFilter } from './WarehouseForOwnerFilter.model';
import { WarehouseInfo } from './WarehouseInfo.model';
import { WarehouseLock } from './WarehouseLock.model';
import { WarehousePodorys } from './WarehousePodorys.model';
import { WarehouseV1Lock } from './WarehouseV1Lock.model';
import { WarehouseV2Lock } from './WarehouseV2Lock.model';
import { WriteUserCmd } from './WriteUserCmd.model';
export {
  Accessibility,
  Address,
  AdminContract,
  AdminContractListItem,
  AdminReferralRewardTransaction,
  AdminRent,
  AdminRentFilter,
  AdminRentPaying,
  AdminWarehouse,
  AdminWarehouseListItem,
  AppUser,
  AppUserFilter,
  AutoregistrationInvoicingDetailErrors,
  AutoregistrationInvoicingDetails,
  AutoregistrationPlacedOrderResponse,
  BooleanValue,
  Box,
  BoxConsumerPrice,
  BoxDepositOption,
  BoxDimensions,
  BoxFilter,
  BoxFrom,
  BoxGeometry,
  BoxGeometryInput,
  BoxGroup,
  BoxInUseInfo,
  BoxInsurance,
  BoxInsuranceOption,
  BoxItem,
  BoxLock,
  BoxLockInfo,
  BoxV1Lock,
  BoxV2Lock,
  BoxWithInUseInfo,
  BrowserErrorContext,
  BrowserErrorReport,
  CancelContractCmd,
  ChangeMyPasswordCmd,
  ClientContractRestApiTypes,
  ClientInfo,
  ClientReferralInfo,
  ClientUser,
  ClientUserFilter,
  ClientWarehouse,
  ClientWarehouseListItem,
  CompanyDetails,
  ContractCancellationOptions,
  ContractInvoicingOption,
  ContractInvoicingType,
  Coordinates,
  CreateBoxCmd,
  CurrentObsadenost,
  Dashboard,
  DashboardFilter,
  Day,
  DayCmd,
  DayInterval,
  DeactivateWarehouseCmd,
  DeactivationInfo,
  DebitCmd,
  DeductionFromDepositCommand,
  Discount,
  Document,
  DownloadLink,
  EditBoxCmd,
  EntityCreated,
  Features,
  FirstMonthFree,
  FirstMonthFreeDiscountInfo,
  FranchiseContact,
  FranchiseContactFilter,
  FranchisingContactRequest,
  IntegerValue,
  Interval,
  InvoiceItem,
  InvoiceItemVatPrice,
  InvoiceItems,
  InvoicePreview,
  InvoicingDetails,
  IsoChronology,
  JobRestApiTypes,
  JobRun,
  Kpi,
  KpiObsadenost,
  KpiObsadenostAreal,
  KpiObsadenostDaily,
  KpiObsadenostNumeral,
  KpiPerInterval,
  KpiPerWarehouse,
  KpiStatistikyZmluv,
  Locale,
  LockPin,
  MarkPaidCmd,
  Money,
  MoneyWithoutCurrency,
  MyContract,
  MyContractFilter,
  MyRent,
  MyRentFilter,
  OrderInputs,
  OrderOffer,
  OrderResult,
  OrderSummary,
  OrderSummaryRequest,
  Owner,
  OwnerAndClientContractFilter,
  OwnerEditCmd,
  PayManuallyCmd,
  PayManuallyResponse,
  PlaceOrderCmd,
  PlacedOrderInfo,
  PodorysBox,
  Point,
  PromoDiscount,
  PromoDiscountEffect,
  PromoWithBoxes,
  PublicTransportAccessibility,
  ReferralRewardAccountState,
  ReferralRewardTransactionFilter,
  RefundCommand,
  RegisterUserCmd,
  RegistrationResponse,
  ReportError,
  RequestCancellationCmd,
  RuntimeInfo,
  RysAuditListItem,
  RysAuditFilter,
  SetGeometryCmd,
  SetInvocingDetailsCmd,
  SetLocalizationCmd,
  SubscribeToNewFreeBoxBody,
  SuperAdminOrder,
  SwaggerPageable,
  Tenant,
  VatPrice,
  WarehouseBoxPrice,
  WarehouseContractFilter,
  WarehouseCreateCmd,
  WarehouseDiscount,
  WarehouseEditCmd,
  WarehouseFilterForAdmin,
  WarehouseForOwnerFilter,
  WarehouseInfo,
  WarehouseLock,
  WarehousePodorys,
  WarehouseV1Lock,
  WarehouseV2Lock,
  WriteUserCmd
};
export const apiInfo = {
  version: '0.1.0'
};
export const api = {
  Accessibility,
  Address,
  AdminContract,
  AdminContractListItem,
  AdminReferralRewardTransaction,
  AdminRent,
  AdminRentFilter,
  AdminRentPaying,
  AdminWarehouse,
  AdminWarehouseListItem,
  AppUser,
  AppUserFilter,
  AutoregistrationInvoicingDetailErrors,
  AutoregistrationInvoicingDetails,
  AutoregistrationPlacedOrderResponse,
  BooleanValue,
  Box,
  BoxConsumerPrice,
  BoxDepositOption,
  BoxDimensions,
  BoxFilter,
  BoxFrom,
  BoxGeometry,
  BoxGeometryInput,
  BoxGroup,
  BoxInUseInfo,
  BoxInsurance,
  BoxInsuranceOption,
  BoxItem,
  BoxLock,
  BoxLockInfo,
  BoxV1Lock,
  BoxV2Lock,
  BoxWithInUseInfo,
  BrowserErrorContext,
  BrowserErrorReport,
  CancelContractCmd,
  ChangeMyPasswordCmd,
  ClientContractRestApiTypes,
  ClientInfo,
  ClientReferralInfo,
  ClientUser,
  ClientUserFilter,
  ClientWarehouse,
  ClientWarehouseListItem,
  CompanyDetails,
  ContractCancellationOptions,
  ContractInvoicingOption,
  ContractInvoicingType,
  Coordinates,
  CreateBoxCmd,
  CurrentObsadenost,
  Dashboard,
  DashboardFilter,
  Day,
  DayCmd,
  DayInterval,
  DeactivateWarehouseCmd,
  DeactivationInfo,
  DebitCmd,
  DeductionFromDepositCommand,
  Discount,
  Document,
  DownloadLink,
  EditBoxCmd,
  EntityCreated,
  Features,
  FirstMonthFree,
  FirstMonthFreeDiscountInfo,
  FranchiseContact,
  FranchiseContactFilter,
  FranchisingContactRequest,
  IntegerValue,
  Interval,
  InvoiceItem,
  InvoiceItemVatPrice,
  InvoiceItems,
  InvoicePreview,
  InvoicingDetails,
  IsoChronology,
  JobRestApiTypes,
  JobRun,
  Kpi,
  KpiObsadenost,
  KpiObsadenostAreal,
  KpiObsadenostDaily,
  KpiObsadenostNumeral,
  KpiPerInterval,
  KpiPerWarehouse,
  KpiStatistikyZmluv,
  Locale,
  LockPin,
  MarkPaidCmd,
  Money,
  MoneyWithoutCurrency,
  MyContract,
  MyContractFilter,
  MyRent,
  MyRentFilter,
  OrderInputs,
  OrderOffer,
  OrderResult,
  OrderSummary,
  OrderSummaryRequest,
  Owner,
  OwnerAndClientContractFilter,
  OwnerEditCmd,
  PayManuallyCmd,
  PayManuallyResponse,
  PlaceOrderCmd,
  PlacedOrderInfo,
  PodorysBox,
  Point,
  PromoDiscount,
  PromoDiscountEffect,
  PromoWithBoxes,
  PublicTransportAccessibility,
  ReferralRewardAccountState,
  ReferralRewardTransactionFilter,
  RefundCommand,
  RegisterUserCmd,
  RegistrationResponse,
  ReportError,
  RequestCancellationCmd,
  RuntimeInfo,
  RysAuditListItem,
  RysAuditFilter,
  SetGeometryCmd,
  SetInvocingDetailsCmd,
  SetLocalizationCmd,
  SubscribeToNewFreeBoxBody,
  SuperAdminOrder,
  SwaggerPageable,
  Tenant,
  VatPrice,
  WarehouseBoxPrice,
  WarehouseContractFilter,
  WarehouseCreateCmd,
  WarehouseDiscount,
  WarehouseEditCmd,
  WarehouseFilterForAdmin,
  WarehouseForOwnerFilter,
  WarehouseInfo,
  WarehouseLock,
  WarehousePodorys,
  WarehouseV1Lock,
  WarehouseV2Lock,
  WriteUserCmd
};
export const allModels = [
  Accessibility,
  Address,
  AdminContract,
  AdminContractListItem,
  AdminReferralRewardTransaction,
  AdminRent,
  AdminRentFilter,
  AdminRentPaying,
  AdminWarehouse,
  AdminWarehouseListItem,
  AppUser,
  AppUserFilter,
  AutoregistrationInvoicingDetailErrors,
  AutoregistrationInvoicingDetails,
  AutoregistrationPlacedOrderResponse,
  BooleanValue,
  Box,
  BoxConsumerPrice,
  BoxDepositOption,
  BoxDimensions,
  BoxFilter,
  BoxFrom,
  BoxGeometry,
  BoxGeometryInput,
  BoxGroup,
  BoxInUseInfo,
  BoxInsurance,
  BoxInsuranceOption,
  BoxItem,
  BoxLock,
  BoxLockInfo,
  BoxV1Lock,
  BoxV2Lock,
  BoxWithInUseInfo,
  BrowserErrorContext,
  BrowserErrorReport,
  CancelContractCmd,
  ChangeMyPasswordCmd,
  ClientContractRestApiTypes,
  ClientInfo,
  ClientReferralInfo,
  ClientUser,
  ClientUserFilter,
  ClientWarehouse,
  ClientWarehouseListItem,
  CompanyDetails,
  ContractCancellationOptions,
  ContractInvoicingOption,
  ContractInvoicingType,
  Coordinates,
  CreateBoxCmd,
  CurrentObsadenost,
  Dashboard,
  DashboardFilter,
  Day,
  DayCmd,
  DayInterval,
  DeactivateWarehouseCmd,
  DeactivationInfo,
  DebitCmd,
  DeductionFromDepositCommand,
  Discount,
  Document,
  DownloadLink,
  EditBoxCmd,
  EntityCreated,
  Features,
  FirstMonthFree,
  FirstMonthFreeDiscountInfo,
  FranchiseContact,
  FranchiseContactFilter,
  FranchisingContactRequest,
  IntegerValue,
  Interval,
  InvoiceItem,
  InvoiceItemVatPrice,
  InvoiceItems,
  InvoicePreview,
  InvoicingDetails,
  IsoChronology,
  JobRestApiTypes,
  JobRun,
  Kpi,
  KpiObsadenost,
  KpiObsadenostAreal,
  KpiObsadenostDaily,
  KpiObsadenostNumeral,
  KpiPerInterval,
  KpiPerWarehouse,
  KpiStatistikyZmluv,
  Locale,
  LockPin,
  MarkPaidCmd,
  Money,
  MoneyWithoutCurrency,
  MyContract,
  MyContractFilter,
  MyRent,
  MyRentFilter,
  OrderInputs,
  OrderOffer,
  OrderResult,
  OrderSummary,
  OrderSummaryRequest,
  Owner,
  OwnerAndClientContractFilter,
  OwnerEditCmd,
  PayManuallyCmd,
  PayManuallyResponse,
  PlaceOrderCmd,
  PlacedOrderInfo,
  PodorysBox,
  Point,
  PromoDiscount,
  PromoDiscountEffect,
  PromoWithBoxes,
  PublicTransportAccessibility,
  ReferralRewardAccountState,
  ReferralRewardTransactionFilter,
  RefundCommand,
  RegisterUserCmd,
  RegistrationResponse,
  ReportError,
  RequestCancellationCmd,
  RuntimeInfo,
  RysAuditListItem,
  RysAuditFilter,
  SetGeometryCmd,
  SetInvocingDetailsCmd,
  SetLocalizationCmd,
  SubscribeToNewFreeBoxBody,
  SuperAdminOrder,
  SwaggerPageable,
  Tenant,
  VatPrice,
  WarehouseBoxPrice,
  WarehouseContractFilter,
  WarehouseCreateCmd,
  WarehouseDiscount,
  WarehouseEditCmd,
  WarehouseFilterForAdmin,
  WarehouseForOwnerFilter,
  WarehouseInfo,
  WarehouseLock,
  WarehousePodorys,
  WarehouseV1Lock,
  WarehouseV2Lock,
  WriteUserCmd
];
