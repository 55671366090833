export const RuntimeInfo = {
  name: 'RuntimeInfo',
  attrs: {
    apiVersion: {
      name: 'apiVersion',
      id: 'RuntimeInfo.apiVersion',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    defaultLocaleCountry: {
      name: 'defaultLocaleCountry',
      id: 'RuntimeInfo.defaultLocaleCountry',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    defaultLocaleLanguage: {
      name: 'defaultLocaleLanguage',
      id: 'RuntimeInfo.defaultLocaleLanguage',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'RuntimeInfo.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    version: {
      name: 'version',
      id: 'RuntimeInfo.version',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
