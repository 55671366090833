import { AttributeRenderConfig } from 'src/model/attribute/rendering'
import { Money, MoneyWithoutCurrency } from 'src/api/defs/Money.type'
import React, { ComponentType } from 'react'
import { Text } from 'src/ui/basic/Text'
import { InputComponentProps } from 'src/ui/basic/input/types'
import { View } from 'src/ui/basic/View'
import { css } from 'src/ui/style/css'
import { Icon } from 'src/ui/basic/icon/Icon'
import { icon } from 'src/ui/basic/icon/icons'
import { NumberInput } from 'src/ui/basic/input/NumberInput'
import { RenderValue } from 'src/model/value'
import { useFormatters } from 'src/formatters/useFormatters'

type MoneyEditorComp = ComponentType<
    InputComponentProps<Money> & {
        fullWidth?: boolean
    }
>

export const MoneyEditor: MoneyEditorComp = ({ input, fullWidth }) => (
    <View
        horizontal
        css={css`
            ${!fullWidth && 'max-width: 9em'};
            > * {
                text-align: right;
            }
        `}
    >
        <NumberInput
            input={{
                value: input.value.value,
                onChange: value =>
                    input.onChange({
                        value
                    })
            }}
        />
        <Icon
            css={th => css`
                margin-left: 0.5em;
                font-size: 3em;
                color: ${th.colors.secondary};
            `}
            icon={icon.Coins}
        />
    </View>
)

const MoneyValue: ComponentType<{ value: Money; hideCurrency?: boolean }> = ({
    value,
    hideCurrency
}) => {
    const { strMoney } = useFormatters()
    if (!hideCurrency) {
        const money = strMoney(value)
        return <Text>{money}</Text>
    }

    const money = strMoney(value, hideCurrency)
    return <Text>{money}</Text>
}

export const RenderMoneyValue: RenderValue<Money> = value => (
    <MoneyValue value={value} />
)

export const RenderMoneyWithoutCurrencyValue: RenderValue<
    MoneyWithoutCurrency
> = value => <MoneyValue value={value} hideCurrency={true} />

export const MoneyRenderingCfg: AttributeRenderConfig<Money> = {
    renderer: RenderMoneyValue,
    editor: MoneyEditor,
    defaultValue: () => ({
        value: 0
    })
}

export const MoneyRenderingCfgWithoutCurrency: AttributeRenderConfig<MoneyWithoutCurrency> =
    {
        renderer: RenderMoneyWithoutCurrencyValue,
        editor: MoneyEditor,
        defaultValue: () => ({
            value: 0
        })
    }
