export const OrderOffer = {
  name: 'OrderOffer',
  attrs: {
    box: {
      name: 'box',
      id: 'OrderOffer.box',
      type: {
        name: 'object',
        of: 'Box'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    insurance: {
      name: 'insurance',
      id: 'OrderOffer.insurance',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'BoxInsuranceOption'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    invoicing: {
      name: 'invoicing',
      id: 'OrderOffer.invoicing',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'ContractInvoicingOption'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouse: {
      name: 'warehouse',
      id: 'OrderOffer.warehouse',
      type: {
        name: 'object',
        of: 'WarehouseInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
