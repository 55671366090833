export const EditBoxCmd = {
  name: 'EditBoxCmd',
  attrs: {
    active: {
      name: 'active',
      id: 'EditBoxCmd.active',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    dimensions: {
      name: 'dimensions',
      id: 'EditBoxCmd.dimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lock: {
      name: 'lock',
      id: 'EditBoxCmd.lock',
      type: {
        name: 'object',
        of: 'BoxLock'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    sizeAsXs: {
      name: 'sizeAsXs',
      id: 'EditBoxCmd.sizeAsXs',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    visible: {
      name: 'visible',
      id: 'EditBoxCmd.visible',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
