import {
    AttributeRenderConfig,
    RenderAttributeValue
} from 'src/model/attribute/rendering/index'

export type ReadOnlyObjectRenderConfigType<T> = (
    renderer: RenderAttributeValue<T>
) => AttributeRenderConfig<T>

export const readOnlyObjectRenderConfig: ReadOnlyObjectRenderConfigType<any> = renderer => ({
    renderer,
    editor: () => {
        throw new Error('Editing unsupported')
    },
    defaultValue: () => ({})
})
