import React, { ComponentType, useEffect } from 'react'
import { getAppContext } from 'src/context/AppContext'
import { useStore } from '@ps-aux/react-app-core'
import { apiEnums } from 'src/api/defs/enums.model'
import { Language } from 'src/api/defs/enums.type'
import { CountrySelector } from 'src/ui/country-selector/CountrySelector'
import { LanguageFormat } from 'src/types/LanguageFormat'

type LanguageSwitcherProps = { onValueChanged?: () => void }

export const LanguageSwitcher: ComponentType<LanguageSwitcherProps> = ({
    onValueChanged
}) => {
    const [lang, setLang] = useStore(getAppContext().i18n.language)
    // eslint-disable-next-line
    const [format, setFormat] = useStore(getAppContext().i18n.format)
    const languages = { ...apiEnums.Language }
    languages.of = [Language.SK, Language.EN]

    const customLabels = {
        SK: { primary: 'SK' },
        CS: { primary: 'CZ' },
        EN: { primary: 'EN' }
    }

    const onLangChange = (lang: Language) => {
        setLang(lang)
        setFormat(LanguageFormat[lang])
    }

    useEffect(() => {
        if (!lang.toLowerCase().includes(format.toLowerCase())) {
            setFormat(LanguageFormat[lang])
        }
    }, [])

    return (
        <CountrySelector
            selected={lang || Language.EN}
            onSelect={l => {
                onLangChange(l! as Language)
                onValueChanged && onValueChanged()
            }}
            showSelectedLabel={false}
            showOptionLabel={true}
            customLabels={customLabels}
            countries={languages.of}
        />
    )
}
