import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { m } from 'src/api/model/model'
import { EditBoxCmd } from 'src/api/defs/EditBoxCmd.type'
import { CreateBoxCmd } from 'src/api/defs/CreateBoxCmd.type'
import { BoxWithInUseInfo } from 'src/api/defs/BoxWithInUseInfo.type'

export class BoxApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    edit = (id: string, cmd: EditBoxCmd): Promise<any> =>
        this.api.doForId<void, EditBoxCmd>(this.ops().edit, null, id, cmd)

    create = (cmd: CreateBoxCmd): Promise<any> =>
        this.api.do<CreateBoxCmd>(this.ops().create, { data: cmd })

    detail = (id: string): Promise<BoxWithInUseInfo> =>
        this.api.doForId<BoxWithInUseInfo>(
            this.ops().detail,
            m.BoxWithInUseInfo,
            id
        )

    confirmBoxToReady = (id: number): Promise<any> =>
        this.api.doForId<void, any>(
            this.ops().confirmBoxToReady,
            null,
            id.toString()
        )

    private ops = () => (this.isOwner() ? apiOps.OwnerUserBox : apiOps.Box)
}
