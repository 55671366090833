export const FranchiseContact = {
  name: 'FranchiseContact',
  attrs: {
    createdAt: {
      name: 'createdAt',
      id: 'FranchiseContact.createdAt',
      type: {
        name: 'date'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    email: {
      name: 'email',
      id: 'FranchiseContact.email',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[a-zA-Z0-9+._%-]{1,256}@[a-zA-Z0-9][a-zA-Z0-9-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9-]{0,64})*\.[a-zA-Z0-9][a-zA-Z0-9-]{0,25}$/
        }
      ],
      extra: {}
    },
    exportId: {
      name: 'exportId',
      id: 'FranchiseContact.exportId',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'FranchiseContact.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    phoneNumber: {
      name: 'phoneNumber',
      id: 'FranchiseContact.phoneNumber',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^(\+|00)(\s?\d){7,15}$/
        }
      ],
      extra: {}
    }
  }
};
