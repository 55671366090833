import { apiOps } from 'src/api/defs/apiOps'
import { HigherApiClient } from 'src/api/client/ApiClient'
import { ListReq } from 'src/model/api/ListReq'
import { Page } from 'src/model/api/Page'
import { AdminRent } from 'src/api/defs/AdminRent.type'
import { DownloadLink } from 'src/api/defs/DownloadLink.type'
import { m } from 'src/api/model/model'
import { AllNullable } from 'src/api/AllNullable'
import { AdminRentFilter } from 'src/api/defs/AdminRentFilter.type'

export type RentListFilter = AllNullable<AdminRentFilter>

export class RentApi {
    constructor(private api: HigherApiClient, private isOwner: () => boolean) {}

    all = (req: ListReq<RentListFilter>): Promise<Page<AdminRent>> => {
        return this.api.getPage<AdminRent, RentListFilter>(
            this.isOwner()
                ? apiOps.OwnerRent.readAllForOwner
                : apiOps.AdminRent.readAllForAdmin,
            m.AdminRent,
            req
        )
    }

    detail = (id: string): Promise<AdminRent> =>
        this.api.doForId<AdminRent>(
            this.isOwner()
                ? apiOps.OwnerRent.readDetailForOwner
                : apiOps.AdminRent.readDetailForAdmin,
            m.AdminRent,
            id
        )

    downloadInvoice = (paymentId: string): Promise<DownloadLink> =>
        this.api.do(
            this.isOwner()
                ? apiOps.OwnerRent.invoiceDownloadForOwner
                : apiOps.AdminRent.invoiceDownload,
            {
                pathParams: {
                    id: paymentId
                }
            }
        )

    markPaid = (id: string, paidAt: string): Promise<void> =>
        this.isOwner()
            ? Promise.reject(new Error('Only admin can mark something paid.'))
            : this.api.doForId(apiOps.AdminRent.markPaid, null, id, { paidAt })
}
