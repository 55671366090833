export const MyContractFilter = {
  name: 'MyContractFilter',
  attrs: {
    status: {
      name: 'status',
      id: 'MyContractFilter.status',
      type: {
        name: 'enum',
        id: 'Contract$Status',
        of: ['ACTIVE', 'TERMINATING', 'EVICTED', 'TERMINATED']
      },
      required: false,
      validationRules: [],
      extra: {
        enumId: 'Contract$Status'
      }
    }
  }
};
