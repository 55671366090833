import { InputComponent } from 'src/ui/basic/input/types'
import { Address } from 'src/api/defs/Address.type'
import { m } from 'src/api/model/model'
import { EntityInput } from 'src/model/modify/form/EntityInput'
import React from 'react'
import { RenderValue } from 'src/model/value'
import { InlineAddress } from 'src/business/Warehouse/components/InlineAddress'
import { AttributeRenderConfig } from 'src/model/attribute/rendering'
import { ValidationFunction } from 'src/validation/types'
import { notNull, treeValidator } from '@ps-aux/revalid'

export const addressSchema = {
    city: [notNull()],
    countryCode: [notNull()],
    number: [notNull()],
    zip: [notNull()],
    street: [notNull()]
}

const validateAddress: ValidationFunction = treeValidator(addressSchema)
    .validate

export const AddressEditor: InputComponent<Address> = ({
    input,
    onFocus,
    onBlur
}) => (
    <EntityInput
        model={m.Address}
        validate={validateAddress}
        input={input}
        onFocus={onFocus}
        onBlur={onBlur}
    />
)

export const RenderAddressValue: RenderValue<Address> = value => (
    <InlineAddress value={value} />
)

export const AddressRenderConfig: AttributeRenderConfig<Address> = {
    editor: AddressEditor,
    renderer: RenderAddressValue,
    defaultValue: () => ({})
}
