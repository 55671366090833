export type HttpError = {
    status: number
    code: string
    detail?: ErrorDetail[]
}

export type ErrorDetail = {
    codes: string[]
    arguments: ErrorDetailArgument[]
    defaultMessage: string
    objectName: string
    field: string
    rejectedValue: boolean
    bindingFailure: boolean
    code: string
}

export type ErrorDetailArgument = {
    codes: string[]
    arguments?: any
    defaultMessage: string
    code: string
}

export type AxiosHttpError = {
    response: {
        data: any
        status: number
    }
}

export const extractHttpError = (err: AxiosHttpError): HttpError => {
    return {
        status: err.response.status,
        code: err.response.data.code,
        detail: err.response.data?.detail
    }
}
