export const AdminWarehouse = {
  name: 'AdminWarehouse',
  attrs: {
    accessibility: {
      name: 'accessibility',
      id: 'AdminWarehouse.accessibility',
      type: {
        name: 'object',
        of: 'Accessibility'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    address: {
      name: 'address',
      id: 'AdminWarehouse.address',
      type: {
        name: 'object',
        of: 'Address'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    code: {
      name: 'code',
      id: 'AdminWarehouse.code',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    coords: {
      name: 'coords',
      id: 'AdminWarehouse.coords',
      type: {
        name: 'object',
        of: 'Coordinates'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    country: {
      name: 'country',
      id: 'AdminWarehouse.country',
      type: {
        name: 'enum',
        id: 'Country',
        of: ['SVK', 'CZE']
      },
      required: true,
      validationRules: [],
      extra: {
        enumId: 'Country'
      }
    },
    deactivation: {
      name: 'deactivation',
      id: 'AdminWarehouse.deactivation',
      type: {
        name: 'object',
        of: 'DeactivationInfo'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    discounts: {
      name: 'discounts',
      id: 'AdminWarehouse.discounts',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseDiscount'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    features: {
      name: 'features',
      id: 'AdminWarehouse.features',
      type: {
        name: 'object',
        of: 'Features'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    id: {
      name: 'id',
      id: 'AdminWarehouse.id',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lock: {
      name: 'lock',
      id: 'AdminWarehouse.lock',
      type: {
        name: 'object',
        of: 'WarehouseLock'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'AdminWarehouse.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    ownerName: {
      name: 'ownerName',
      id: 'AdminWarehouse.ownerName',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    prices: {
      name: 'prices',
      id: 'AdminWarehouse.prices',
      type: {
        name: 'list',
        of: {
          name: 'object',
          of: 'WarehouseBoxPrice'
        }
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
