export const FirstMonthFree = {
    name: 'FirstMonthFree',
    attrs: {
        enabled: {
            name: 'enabled',
            id: 'FirstMonthFree.enabled',
            type: {
                name: 'boolean'
            },
            required: true,
            validationRules: [],
            extra: {}
        }
    }
};
