export const DeductionFromDepositCommand = {
    name: 'DeductionFromDepositCommand',
    attrs: {
        deductionAmount: {
            name: 'deductionAmount',
            id: 'DeductionFromDepositCommand.deductionAmount',
            type: {
                name: 'object',
                of: 'Money'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        deductionReason: {
            name: 'deductionReason',
            id: 'DeductionFromDepositCommand.deductionReason',
            type: {
                name: 'string'
            },
            required: true,
            validationRules: [
                {
                    name: 'length',
                    value: {
                        min: 0,
                        max: 512
                    }
                }
            ],
            extra: {}
        }
    }
};
