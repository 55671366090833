import { HigherApiClient } from 'src/api/client/ApiClient'
import { Document } from 'src/api/defs/Document.type'
import { apiOps } from 'src/api/defs/apiOps'
import { DownloadLink } from 'src/api/defs/DownloadLink.type'

export type Doc = Document
export class DocumentsApi {
    constructor(private api: HigherApiClient) {}

    getAll = (): Promise<Doc[]> => this.api.do(apiOps.Documents.all)

    download = (id: string): Promise<DownloadLink> =>
        this.api.doForId(apiOps.Documents.downloadLink, null, id)
}
