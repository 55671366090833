export const CreateBoxCmd = {
  name: 'CreateBoxCmd',
  attrs: {
    active: {
      name: 'active',
      id: 'CreateBoxCmd.active',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    dimensions: {
      name: 'dimensions',
      id: 'CreateBoxCmd.dimensions',
      type: {
        name: 'object',
        of: 'BoxDimensions'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    lock: {
      name: 'lock',
      id: 'CreateBoxCmd.lock',
      type: {
        name: 'object',
        of: 'BoxLock'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    name: {
      name: 'name',
      id: 'CreateBoxCmd.name',
      type: {
        name: 'string'
      },
      required: true,
      validationRules: [
        {
          name: 'pattern',
          value: /^[A-Za-z0-9_][A-Za-z0-9_-]{1,}[A-Za-z0-9_]$/
        }
      ],
      extra: {}
    },
    sizeAsXs: {
        name: 'sizeAsXs',
        id: 'CreateBoxCmd.sizeAsXs',
        type: {
            name: 'boolean'
        },
        required: false,
        validationRules: [],
        extra: {}
    },
    visible: {
      name: 'visible',
      id: 'CreateBoxCmd.visible',
      type: {
        name: 'boolean'
      },
      required: true,
      validationRules: [],
      extra: {}
    },
    warehouseId: {
      name: 'warehouseId',
      id: 'CreateBoxCmd.warehouseId',
      type: {
        name: 'integer'
      },
      required: true,
      validationRules: [],
      extra: {}
    }
  }
};
