export const FirstMonthFreeDiscountInfo = {
    name: 'FirstMonthFreeDiscountInfo',
    attrs: {
        discount: {
            name: 'discount',
            id: 'FirstMonthFreeDiscountInfo.discount',
            type: {
                name: 'object',
                of: 'FirstMonthFree'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        firstMonthFreeDiscount: {
            name: 'firstMonthFreeDiscount',
            id: 'FirstMonthFreeDiscountInfo.firstMonthFreeDiscount',
            type: {
                name: 'object',
                of: 'Money'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        firstMonthFreeDiscountVatRate: {
            name: 'firstMonthFreeDiscountVatRate',
            id: 'FirstMonthFreeDiscountInfo.firstMonthFreeDiscountVatRate',
            type: {
                name: 'double'
            },
            required: true,
            validationRules: [],
            extra: {}
        },
        rentPrice: {
            name: 'rentPrice',
            id: 'FirstMonthFreeDiscountInfo.rentPrice',
            type: {
                name: 'object',
                of: 'VatPrice'
            },
            required: true,
            validationRules: [],
            extra: {}
        }
    }
};
